<template>
  <div class="invoices">
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="hidden-sm-and-down"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon class="mr-1">
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>
    <invoice-details-card
      :invoice="details"
      :is-loading="isLoading"
    />
    <br>
    <payments-table
      :payments-url="'payments'"
      :invoice-id="invoiceId"
    />
    <br>
    <br>
    <customer-details-card
      :customer="details.customer"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mdiArrowLeft } from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import CustomerDetailsCard from '@/components/details-card/CustomerDetailsCard.vue'
import InvoiceDetailsCard from '@/components/details-card/InvoiceDetailsCard.vue'
import PaymentsTable from '@/components/tables/PaymentsTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'customer-details-card': CustomerDetailsCard,
    'invoice-details-card': InvoiceDetailsCard,
    'payments-table': PaymentsTable,
  },
  data() {
    return {
      details: { customer: { user: { name: '', id: '' } }, invoice: {} },
      isLoading: true,
      invoiceId: this.$route.params.id,
      icons: {
        mdiArrowLeft,
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Invoices',
          disabled: false,
          to: { name: 'invoices' },
        },
      ],
    }
  },
  mounted() {
    this.getInvoice()
    if (this.invoiceId) {
      this.breadcrumbs.push({
        text: this.invoiceId,
        disabled: true,
        to: { name: 'invoices.show' },
      })
    }
  },
  methods: {
    getInvoice() {
      this.isLoading = true
      axios
        .get(`invoices/${this.invoiceId}`)
        .then(response => {
          this.details = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
